<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-btn
        class="ml-6 mt-6"
        dark
        elevation="0"
        fab
        icon
        x-large
        style="pointer-events: none;background: rgba(255, 82, 82, 0.15);"
      >
        <v-icon
          color="red"
          class="text-center ml-1"
          style="font-size: 55px;pointer-events: none;"
        >
          mdi-cart-outline
        </v-icon>
      </v-btn>
      <v-card-title class="headline">
        <p class="text-left" style="font-weight: 500; font-size: 18px;">
          Vaciar carrito
        </p>
      </v-card-title>

      <v-card-text>
        <p style="font-weight: 400; color:  #7D7D7D;">
          ¿Estás seguro de que quieres vaciar todos los articulos de tu carrito? Esta acción no se puede deshacer.
        </p>
      </v-card-text>

      <v-card-actions style="background: #F1F1F1;" class="pt-4 pb-4">

        <v-btn width="47%" color="white" @click="closeDialog" class="capitalize-first custom-btn">
          Cancelar
        </v-btn>
        <v-btn width="47%" color="red" dark @click="confirmDelete" class="capitalize-first custom-btn">
          Sí, estoy seguro
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.custom-btn {
  text-transform: none; /* Elimina la transformación de mayúsculas */
  font-family: inherit; /* Hereda el tipo de letra por defecto */
}

.capitalize-first::first-letter {
  text-transform: capitalize; /* Solo la primera letra en mayúscula */
}
</style>
<script>
export default {
  props: {

    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    confirmDelete() {
      this.$emit('confirm-delete');
      this.closeDialog();
    },
  },
};
</script>
